import { BreadcrumbItem, Breadcrumbs, Container, Page } from '@troon/ui';
import { useSearchParams } from '@solidjs/router';
import { Title } from '@solidjs/meta';
import { SearchLocations } from '../../components/search-locations';
import SearchFacilitiesContent from './_components/contents';

export default function SearchFacilities() {
	const [search, setSearch] = useSearchParams();

	return (
		<>
			<Container class="mt-4">
				<Breadcrumbs>
					<BreadcrumbItem href="/courses">Explore courses</BreadcrumbItem>
					<BreadcrumbItem href="/courses/directory">Search results</BreadcrumbItem>
				</Breadcrumbs>
			</Container>
			<Page>
				<Container class="flex flex-col gap-8">
					<Title>Find golf courses | Troon</Title>

					<SearchLocations
						showAllLink
						onSelectPlace={(place) => {
							setSearch({ regionIds: undefined, lat: place.coordinates.latitude, lon: place.coordinates.longitude });
						}}
						onSelectRegion={(region) => {
							setSearch({ regionIds: region.id, lat: undefined, lon: undefined });
						}}
						defaultValue={search.query}
						inputClass="ps-10 py-4"
						mobileInputClass="ps-3 border border-neutral py-4"
						placeholder="Search by city or course name"
					/>

					<SearchFacilitiesContent />
				</Container>
			</Page>
		</>
	);
}
